import React, { useEffect, useState } from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'
import Code from '../../components/Code'
import axios from 'axios'

const meta = {
  title: 'Javascript Integration',
  image: '274877907168',
  path: '/docs/javascript-integration',
  description: 'How to integrate Tueri.io with Javascript',
  keywords: 'image optimization, javascript integration for tueri'
}

const ReactIntegration = ({ data }) => {

  const [tag, setTag] = useState('<stable>')

  useEffect(() => {
    if (tag === '<stable>') {
      axios.get('https://api.github.com/repos/tueri-io/vanilla-tueri/tags')
      .then(res => { setTag(res.data[0].name) })
    }
  }, [])

  return (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
            <script src="//cdn.releasepage.co/js/version.js" data-repo="tueri-io/vanilla-tueri"></script>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <a className='githubLink' target="_blank" rel="noopener noreferrer" href='https://github.com/tueri-io/vanilla-tueri'><span><Img src='https://cdn.tueri.io/274877907174/github-logo_318-53553.jpg' alt='View on GitHub'/></span>View on GitHub</a>

       <p>
         Integrate Tueri.io into your website in minutes with Javascript.
       </p>

        <h2>Installation</h2>
        <span data-version-badge></span>
        <Code type='cli'>{`<script src='https://cdn.jsdelivr.net/gh/tueriapp/vanilla-tueri@${tag.replace(/v/g, '')}/dist/tueri.min.js'></script>`}</Code>

        <h2>Integrate Tueri</h2>
        Change img src to tueri-src 
        
        <Code type='html'>{`<!-- change src -->
<img src="https://cdn.tueri.io/..." alt="Alternate Text"/>

<!-- to tueri-src -->
<img tueri-src="https://cdn.tueri.io/..." alt="Alternate Text/>`}</Code>

    Tueri automatically detects the dimensions of the parent element, then generates and returns your image in exactly the right dimensions to fill the parent element.

    </Layout>
)}

export default ReactIntegration